import React from "react";

function Signup() {
  return (
    <section className="bg-navy text-white">
      <form method="POST" name="Newsletter" data-netlify="true" action="/thank-you">
        <input type="hidden" name="form-name" value="Newsletter" />
        <div className="grid grid-cols-1 lg:grid-cols-12 py-16 px-6">
          <div className="text-center col-span-12 mb-4">
            <h2>Get Campaign Updates</h2>
            <p>Sign up to receive campaign updates, event invitations, and more.</p>
          </div>
          <div className="col-span-4 col-start-4">
            <label htmlFor="email" className="text-gray-600 font-bold h-6">Your Email</label>
            <input className="bg-light_gray bg-opacity-20 text-white h-16 px-4 w-full border border-transparent transition-all hover:border-red" type="email" placeholder="test@example.com" name="Email" required />
          </div>
          <div className="col-span-2">
            <button type="submit" className="btn rounded-none h-16 w-full mb-0 mt-6">Sign Up</button>
          </div>
        </div>
      </form>
    </section>
  );
}

export default Signup;