import * as React from "react"
import Layout from "../containers/layout"
import { StaticImage } from "gatsby-plugin-image"
import Signup from "../components/signup"
import Intro from "../components/intro"
import SEO from "../components/seo"
import { Link } from "gatsby"
import { FaHandsHelping, FaInfoCircle } from "react-icons/fa";

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Home" description="" />
      <main>
        <Intro />
        <Signup />
        <section className="about py-12 md:py-24 px-4 md:px-16">
          <div className="grid grid-cols-1 lg:grid-cols-12">
            <div className="col-span-6 px-12">
              <StaticImage src="../images/family.jpg" alt="District Judge Dawn Moody and Family" className="mb-4" />
              <StaticImage src="../images/fightinghunger.jpg" alt="District Judge Dawn Moody At A Lawyers Fighting Hunger Event" />
            </div>
            <div className="col-span-6">
              <h2>About Judge Dawn Moody</h2>
              <p className="mt-6">Dawn Moody was born in Riverside, California.  She lived in California, Florida, and New Mexico before finally moving to Tulsa in 1982.  She graduated from Cascia Hall Preparatory School, and then attended the University of Tulsa for both her undergraduate and law degrees.</p>
              <p>In 2018, she was elected to the District Bench, where she has overseen a criminal docket.  She was Chief of the Criminal Division in 2018 and again in 2021.</p>

              <p>In her free time, she enjoys cooking and/or baking. Spending time with her husband Chad, their daughter Brynn who is a senior in high school, their black lab Maggie,  as well as going to Stillwater as often as she can to see her son Chase. </p>
              <div className="text-center">
                <Link to="/about" className="btn"><FaInfoCircle className="inline -mt-1 mr-2" /> Read More</Link>
              </div>
            </div>
          </div>
        </section>
        <section className="district bg-light_gray py-12 md:py-24 px-4 md:px-16">
          <div className="grid grid-cols-1 lg:grid-cols-12">
            <div className="col-span-6">
              <h2>Volunteer</h2>
              <p>We need your support! Judge Dawn Moody has officially formed her campaign committee and is seeking re-election in 2022. There are a number of ways you can volunteer, including hosting a fundraiser, putting a sign in your yard, adding your name to our list of supporters, or participating in our get out the vote initiative.</p>
              <Link to="/volunteer" className="btn gray"><FaHandsHelping className="inline" /> Become A Volunteer</Link>

            </div>
            <div className="col-span-6 px-12">
              <StaticImage src="../images/family.jpg" alt="District Judge Dawn Moody and Family" className="mb-4" />
            </div>

          </div>
        </section>
      </main>
    </Layout>
  )
}

export default IndexPage
