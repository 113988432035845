import React from "react";
import { StaticImage } from "gatsby-plugin-image"

const Intro = () => {
  return (
    <section className="intro">
      <div className="grid grid-cols-1 lg:grid-cols-12 bg-court bg-cover items-center pt-36">
        <div className="col-span-5 text-center">
          <StaticImage src="../images/dawn.png" placeholder="none" alt="District Judge Dawn Moody" />
        </div>
        <div className="col-span-7 px-12 text-center">
          <h1 className="text-white py-4"><span className="block font-display text-5xl text-red">Re-Elect</span> Judge Dawn Moody</h1>
          <p className="text-white">Judge Dawn Moody has officially formed her campaign committee and is seeking re-election in 2022. She is extremely thankful for all the support she has had during her time on the District Bench serving the residents of Tulsa County and looks forward to continuing in the future. </p>
        </div>
      </div>
    </section>
  )
}

export default Intro;